export const ROUTE_CONSTANTS = {
  MAIN_PAGE: "/app",

  TRANSFER_SUCCESSFUL: "/app/transfer-successful/:id",
  TRANSFER_FAILED: "/app/transfer-failed/:id",
  TRANSFER_PROCESSING: "/app/transfer-processing/:id",

  SCHEDULE_PAYMENT: "/app/schedule",
  SCHEDULED_PAYMENTS: "/app/scheduled-payments",
  SCHEDULED_DETAILS: "/app/scheduled-details/:id",

  HISTORY_PAGE: "/app/history",
  OUTGOING_DETAILS: "/app/outgoing-details/:id/:action",
  INCOMING_DETAILS: "/app/incoming-details/:id",

  SETTINGS: "/app/settings",
  SET_DEFAULT_ACCOUNT: "/app/settings/default-account/:id",

  FUNDING: "/funding",
  FUNDING_SIGN_IN: "/funding/signin/token",

  EVENT_DEMO: "/event_demo",

  BANNO_PLUGIN: "/app/:method/:idp/banno-plugin",

  SEND_SMS_PAGE: "/app/send-sms",

  TOKEN_SIGN_IN: "/app/signin/token",
  SIGN_OUT: "/app/sign-out",
};
