import React, { useContext, useEffect } from "react";
import OutgoingTransfer from "./History/OutgoingTransfer";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../dataContext";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";
import BottomButton from "../components/BottomButton";
import { ReactComponent as NotFound } from "../assets/ico_not_found.svg";

const { SCHEDULED_DETAILS, SCHEDULE_PAYMENT } = ROUTE_CONSTANTS;

const ScheduledPayments = () => {
  const navigate = useNavigate();
  const {
    scheduledTransfers,
    scheduledTransfersLoading,
    getScheduledTransfers,
  } = useContext(DataContext);

  useEffect(() => {
    getScheduledTransfers();
  }, [getScheduledTransfers]);

  return (
    <main className="page_with_nav">
      {scheduledTransfers.length ? (
        <ul>
          {scheduledTransfers.length &&
            scheduledTransfers.map((transfer, i) => {
              const scheduledTransfer = transfer;
              return (
                <div key={i}>
                  <OutgoingTransfer
                    outgoing={scheduledTransfer}
                    handleLinkClick={() =>
                      navigate(
                        `${SCHEDULED_DETAILS.replace(":id", scheduledTransfer.id)}`
                      )
                    }
                    isScheduled={true}
                  />
                  {i !== scheduledTransfers.length - 1 && (
                    <hr style={{ margin: "10px 0" }} />
                  )}
                </div>
              );
            })}
        </ul>
      ) : (
        <>
          {scheduledTransfersLoading ? (
            <h3 className="center_page_with_nav">
              Scheduled payments are loading.
            </h3>
          ) : (
            <>
              <div
                className="center_page_with_nav"
                style={{
                  flexDirection: "column",
                }}
              >
                <div
                className="bg_sending_text_secondary"
                  style={{
                    height: "60px",
                    width: "60px",
                    borderRadius: "30px",
                  }}
                >
                  <NotFound
                    style={{
                      height: "40px",
                      width: "auto",
                      marginTop: "10px",
                    }}
                    className="fill_sending_bg_primary stroke_sending_bg_primary"
                  />
                </div>

                <h1>Nothing to show</h1>
                <div className="color_sending_text_secondary">
                  Tap the button below to schedule a transfer
                </div>
              </div>
            </>
          )}
        </>
      )}
      {BottomButton(() => navigate(SCHEDULE_PAYMENT), "Schedule New Transfer")}
    </main>
  );
};

export default ScheduledPayments;
