import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";
import { ReactComponent as ArrowLeft } from "../assets/ico_arrow_left.svg";
import { ReactComponent as Clock } from "../assets/ico_clock.svg";
import { ReactComponent as Cancel } from "../assets/ico_cancel.svg";
import { displayTransferRecipient } from "../utils/utils";
import { ReactComponent as Trash } from "../assets/ico_trash.svg";
// import { ReactComponent as Retry } from "../assets/ico_retry.svg";
import { monthDateYear } from "../utils/utils";
import useApi from "../api/api";

const { SCHEDULED_PAYMENTS } = ROUTE_CONSTANTS;

const ScheduledDetails = () => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const schedulerApi = useApi(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [transfer, setTransfer] = useState(false);

  window.scrollTo(0, 0);

  const getScheduledTransferData = useCallback(async () => {
    try {
      const response = await schedulerApi.get(`schedules/${urlParams.id}`);
      setTransfer(response.data);
    } catch (error) {
      console.error("get scheduled transfers error", error);
    } finally {
    }
  }, [schedulerApi, urlParams.id]);

  useEffect(() => {
    getScheduledTransferData();
  }, [getScheduledTransferData]);

  const deleteSchedule = async () => {
    try {
      await schedulerApi.delete(`/schedules/${transfer.id}`);
      getScheduledTransferData();
      // setShowDeleteModal(false);
    } catch (error) {
      console.error("delete scheduled transfer error", error);
    }
  };

  const row = (field, value, dataTestId) => {
    return (
      <div
        className="scheduled_detail"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="color_sending_text_secondary">{field}</div>
        <div style={{ fontSize: "12px", fontWeight: 500 }} data-cy={dataTestId}>
          {value}
        </div>
      </div>
    );
  };

  return (
    <main className="page_without_nav">
      {transfer ? (
        <div>
          {showDeleteModal && <div className="overlay"></div>}
          {showDeleteModal && (
            <div className="modal">
              {transfer.is_active ? (
                <>
                  Delete this payment from your list of scheduled payments?
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                      fontSize: "12px",
                      fontWeight: 550,
                    }}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={deleteSchedule}
                      data-cy="delete_scheduled_transfer"
                    >
                      Yes, delete it
                    </span>
                    <span
                      onClick={() => setShowDeleteModal(false)}
                      className="color_sending_bg_secondary"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Cancel
                    </span>
                  </div>
                </>
              ) : (
                <>
                  Scheduled transfer successfully deleted. All future payments
                  aligned with this schedule will no longer occur.
                  <div
                    style={{
                      marginTop: "20px",
                      fontSize: "12px",
                      fontWeight: 550,
                    }}
                  >
                    <span
                      onClick={() => setShowDeleteModal(false)}
                      className="color_sending_bg_secondary"
                      style={{
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                    >
                      Close
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
          <div
            className="bg_sending_bg_tertiary"
            style={{
              margin: "-25px -20px 0 -20px",
              textAlign: "center",
              padding: "15px 15px 25px 15px ",
            }}
          >
            <div
              className="center_div"
              style={{
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <ArrowLeft
                onClick={() => navigate(SCHEDULED_PAYMENTS)}
                className="stroke_sending_text_primary"
                style={{ height: "24px", cursor: "pointer" }}
                data-cy="go_back_icon"
              />
              <div
                className="center_div bg_sending_bg_primary"
                style={{
                  height: "36px",
                  width: "36px",
                  borderRadius: "18px",
                }}
              >
                {transfer.status === "FAILED" ? (
                  <Cancel
                    style={{ height: "18px" }}
                    className="fill_sending_alert_color"
                  />
                ) : (
                  <Clock
                    style={{ height: "18px" }}
                    className="fill_sending_bg_secondary"
                  />
                )}
              </div>
              <div
                style={{ fontSize: "30px", marginTop: "-19px", width: "28px" }}
              >
                {/* TODO do we need these ellipses? they are in designs, but seemingly go nowhere. I think for ... */}
              </div>
            </div>
            <div
              className={
                transfer.status === "FAILED" ? "color_sending_alert_color" : {}
              }
            >
              <div style={{ fontSize: "11px" }}>
                {/* TODO fail reason */}
                {transfer.status === "FAILED" ? <>Failed</> : <>Upcoming</>}
              </div>
              <h1 style={{ margin: "3px 0" }}>
                ${parseFloat(transfer.amount.value).toFixed(2)}
              </h1>
            </div>
            <div style={{ fontSize: "11px" }}>
              {displayTransferRecipient(transfer.recipient, true)}
            </div>
          </div>
          <div
            style={{
              margin: "20px 0",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* TODO {transfer.status === "FAILED" && (
            <button
              onClick={() => console.log("retry scheduled xfer")}
              className="scheduled_button"
            >
              Retry
              <Retry className="stroke_sending_bg_secondary" />
            </button>
            )} */}
            {transfer.is_active && (
              <>
                <button
                  className="scheduled_button"
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete
                  <Trash className="stroke_sending_bg_secondary" />
                </button>
              </>
            )}
          </div>
          <h5 style={{ margin: "30px 0 15px 0" }}>Transfer Details</h5>
          {row(
            "Amount",
            `$${parseFloat(transfer.amount.value).toFixed(2)}`,
            "amount"
          )}
          {row(
            "Send to",
            displayTransferRecipient(transfer.recipient, true),
            "recipient"
          )}
          <hr style={{ margin: "15px 0" }} />
          {transfer.is_active && (
            <>
              <h5>Schedule Details</h5>
              {row(
                "Repeat",
                transfer.repeat_frequency.charAt(0).toUpperCase() +
                  transfer.repeat_frequency.substr(1).toLowerCase(),
                "repeat_frequency"
              )}
              {row(
                "Next transfer",
                monthDateYear(
                  new Date(
                    `${transfer.next_occurrence?.split("T")[0]}T00:00:00`
                  )
                ),
                "next_occurrence"
              )}
              {row(
                "End date",
                monthDateYear(
                  new Date(`${transfer.end_date?.split(" ")[0]}T00:00:00`)
                ),
                "end_date"
              )}
              <hr style={{ margin: "15px 0" }} />
            </>
          )}

          <h5>Comment</h5>
          <div
            className="scheduled_detail color_sending_text_secondary"
            data-cy="comment"
          >
            {transfer.comment}
          </div>
        </div>
      ) : (
        <div className="center_page">Scheduled Payment Not Found</div>
      )}
    </main>
  );
};

export default ScheduledDetails;
