import React, { useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import SendSms from "../components/SendSMS";



export const SendSmsPage = () => {
  const [searchParams] = useSearchParams();


  const phoneNumber = searchParams.get('phoneNumber');
  const smsMessage = searchParams.get('smsMessage');

  useEffect(() => {
    // console.log(phoneNumber, searchParams)
    localStorage.setItem('mobileProvider', null);
  }, []);



  return (
    <main
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <p>Send text</p>
      <SendSms identifier={phoneNumber} disabled={false} message={smsMessage}></SendSms>
    </main>
  );
};

export default SendSmsPage;
