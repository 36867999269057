import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";
import { DataContext } from "../dataContext";
import { ReactComponent as ArrowLeft } from "../assets/ico_arrow_left.svg";
import { ReactComponent as Calendar } from "../assets/ico_calendar.svg";
import { ReactComponent as Gear } from "../assets/ico_settings.svg";
import { ReactComponent as List } from "../assets/ico_list.svg";
import { ReactComponent as HandShake } from "../assets/ico_handshake.svg";
import { ReactComponent as House } from "../assets/ico_house.svg";
import { ReactComponent as Info } from "../assets/ico_info.svg";

const {
  HISTORY_PAGE,
  SETTINGS,
  MAIN_PAGE,
  EVENT_DEMO,
  SCHEDULE_PAYMENT,
  SCHEDULED_PAYMENTS,
  SIGN_OUT,
} = ROUTE_CONSTANTS;

const NavBar = ({ branding, homePath }) => {
  const navigate = useNavigate();
  const { scheduledAlert, p2pConfig } = useContext(DataContext);

  const [loginType] = useState(window.localStorage.getItem("loginType"));

  const path = window.location.pathname;

  const sendNav = (switchIcon, isDemoApp) => {
    return (
      <nav style={{ padding: "18px 20px", borderRadius: "0 0 12px 12px" }}>
        <h1 style={{ margin: "3.5px 0" }}>Send Money</h1>
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {switchIcon}
            <span
              aria-label="History"
              className="nav_icon"
              onClick={() => navigate(HISTORY_PAGE)}
              data-cy="transfer_history_icon"
            >
              <List className="fill_sending_bg_primary" />
            </span>
            {!isDemoApp && (
              <>
                {p2pConfig.scheduled_recurring_enabled && (
                  <span
                    aria-label="Calendar"
                    className="nav_icon"
                    onClick={() => navigate(SCHEDULED_PAYMENTS)}
                    data-cy="scheduled_transfers_icon"
                  >
                    <Calendar className="fill_sending_bg_secondary stroke_sending_bg_primary" />
                    {scheduledAlert && (
                      <Info
                        style={{ height: "15px", margin: "0 -9px -3px -15px" }}
                        className="fill_sending_alert_color stroke_sending_bg_primary"
                      />
                    )}
                  </span>
                )}
                <span
                  aria-label="Settings"
                  className="nav_icon"
                  onClick={() => navigate(SETTINGS)}
                  data-cy="settings_icon"
                >
                  <Gear className="fill_sending_bg_primary" />
                </span>
              </>
            )}

            {loginType !== "sso" && (
              <div
                aria-label="Log out"
                className="nav_icon"
                onClick={() => navigate(SIGN_OUT)}
              >
                Log Out
              </div>
            )}
          </div>
        </div>
      </nav>
    );
  };

  const genericNav = (title, navigateTo) => {
    return (
      <nav style={{ justifyContent: "start" }}>
        <div
          aria-label="Go back"
          className="nav_icon"
          onClick={() => navigate(navigateTo)}
          style={{ margin: "2px 5px -2px 20px" }}
          data-cy="go_back_icon"
        >
          <ArrowLeft className="stroke_sending_bg_primary" />
        </div>
        <h2>{title}</h2>
      </nav>
    );
  };

  const toDemoIcon = () => {
    return (
      <>
        {branding.event_demo && (
          <span
            aria-label="Demo"
            className="nav_icon"
            onClick={() => navigate(EVENT_DEMO)}
          >
            <HandShake className="fill_sending_bg_secondary stroke_sending_bg_primary" />
          </span>
        )}
      </>
    );
  };

  const toHomeIcon = () => {
    return (
      <span
        aria-label="Home"
        className="nav_icon"
        onClick={() => navigate(MAIN_PAGE)}
      >
        <House className="fill_sending_bg_secondary stroke_sending_bg_primary" />
      </span>
    );
  };

  const pathOrTrailingSlash = (route) => {
    return path === route || path === route.concat("/");
  };

  return (
    <>
      {(() => {
        switch (true) {
          case pathOrTrailingSlash(MAIN_PAGE):
            return <>{sendNav(toDemoIcon(), false)}</>;
          case pathOrTrailingSlash(EVENT_DEMO) && branding.event_demo:
            return <>{sendNav(toHomeIcon(), true)}</>;
          case pathOrTrailingSlash(HISTORY_PAGE):
            return <>{genericNav("Transfers", homePath)}</>;
          case pathOrTrailingSlash(SETTINGS):
            return <>{genericNav("Settings", MAIN_PAGE)}</>;
          case pathOrTrailingSlash(SCHEDULE_PAYMENT) &&
            p2pConfig.scheduled_recurring_enabled:
            return <>{genericNav("Schedule payment", homePath)}</>;
          case pathOrTrailingSlash(SCHEDULED_PAYMENTS) &&
            p2pConfig.scheduled_recurring_enabled:
            return <>{genericNav("Scheduled payments", homePath)}</>;
          case path.includes("default-account"):
            return <>{genericNav("Set default card", SETTINGS)}</>;
          case path.includes("ing-details/") && !path.includes("cancel"):
            return <>{genericNav("Transfer Detail", HISTORY_PAGE)}</>;
          default:
            return null;
        }
      })()}
    </>
  );
};

export default NavBar;
